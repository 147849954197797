<template>
  <div class="bg-white shadow-sm rounded-lg p-4">
    <!-- <div class="row mb-2 justify-content-between" v-show="true">
      
    </div> -->
    <div class="row mb-4">
      <div v-if="discriminator === 'administrator'" class="col-2 ml-0 pr-2 mb-4">
        <multiselect class="multiselect-top" v-model="officeFilter" @input="filterData" :options="offices"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Offices" label="office_name" track-by="id" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.office_name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div v-if="clients.length > 0" class="col-2 ml-0 pr-2 mb-4">
        <multiselect class="multiselect-top" v-model="clientFilter" @input="filterData" :options="clients"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Client" label="name" track-by="uuid" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <!-- <div v-if="users.length > 0" class="col-2 ml-0 pr-2 mb-4">
        <multiselect
          class="multiselect-top"
          v-model="userFilter"
          @input="filterData"
          :options="users"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select User"
          label="name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span
            ></template
          >
        </multiselect>
      </div> -->
      <div v-if="clients.length > 0" class="col-2 ml-0 pr-2">
        <multiselect class="multiselect-bottom" v-model="statusClientFilter" @input="filterData" :options="statusClient"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Client Status" label="status" track-by="status" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.status;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-0 pr-2">
        <multiselect class="multiselect-bottom" v-model="statusFilter" @input="filterData" :options="status"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Transaction Status" label="status" track-by="status" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.status;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-0 pr-2 mb-4">
        <multiselect class="multiselect-bottom" v-model="yearFilter" @input="filterData" :options="years" :multiple="true"
          :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Select Year" label="year"
          track-by="year" :select-label="''" :deselect-label="''" @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.year;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-0 pr-2 mb-4">
        <multiselect class="multiselect-bottom" v-model="quarterFilter" @input="filterData" :options="quarters"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select Quarter" label="quarter" track-by="quarter" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.quarter;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
      <div class="col-2 ml-0 pr-2 mb-4">
        <multiselect class="multiselect-bottom" v-model="stateFilter" @input="filterData" :options="states"
          :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true"
          placeholder="Select State" label="name" track-by="id" :select-label="''" :deselect-label="''"
          @open="openBackgroundOpacity" @close="closeBackgroundOpacity">
          <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen">{{
                values
                  .map((item) => {
                    return item.name;
                  })
                  .join(", ")
              }}</span></template>
        </multiselect>
      </div>
    </div>

    <el-table :data="tableData" class="w-100" :default-sort="{ prop: 'client.clientName', order: 'ascending' }">
      <el-table-column label="Client Name" prop="client.clientName" :show-overflow-tooltip="true" sortable
        width="200"></el-table-column>
      <el-table-column width="100" label="Form" prop="form.name" sortable :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="State" width="60" prop="form.state.name" :show-overflow-tooltip="true"
        sortable></el-table-column>
      <el-table-column prop="quarter" label="Quarter" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope">{{
          scope.row.year + " " + scope.row.quarter
        }}</template>
      </el-table-column>
      <el-table-column prop="dueDate" label="Due Date" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope">{{
          scope.row.dueDate | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column prop="grossSales" label="Gross Sales" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope" ><span style="display: flex;"><span style="flex: 1;">$</span> <span>{{ new Intl.NumberFormat('en-US').format(scope.row.grossSales) }}</span></span></template>
      </el-table-column>
      <el-table-column prop="nonTaxable" label="Non - Taxable" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope"><span style="display: flex;"><span style="flex: 1;">$</span> <span>{{ new Intl.NumberFormat('en-US').format(scope.row.nonTaxable) }}</span></span></template></el-table-column>
      <el-table-column prop="NetTaxableSales" label="Net Tax Due" :show-overflow-tooltip="true" sortable><template
          slot-scope="scope"> <span style="display: flex;"><span style="flex: 1;">$</span> <span>{{ new Intl.NumberFormat('en-US').format(scope.row.NetTaxableSales)
          }}</span></span></template></el-table-column>
      <el-table-column prop="paymentAmount" label="Tax Payment" :show-overflow-tooltip="true" sortable><template
          slot-scope="scope"><span style="display: flex;"><span style="flex: 1;">$</span> <span>{{
            new Intl.NumberFormat('en-US').format(scope.row.paymentAmount)
          }}</span></span></template></el-table-column>
      <el-table-column prop="processed" label="Processed" :show-overflow-tooltip="true" sortable></el-table-column>
      <el-table-column prop="responsible.name" label="Processed By" :show-overflow-tooltip="true"
        sortable></el-table-column>
      <el-table-column prop="processedDate" label="Processed Date" :show-overflow-tooltip="true" sortable>
        <template slot-scope="scope">{{
          scope.row.processedDate | moment("MM-DD-YYYY")
        }}</template>
      </el-table-column>
      <el-table-column label="" width="80">
        <template slot-scope="scope">
          <center>
            <el-button-group>
              <el-button size="mini" icon="el-icon-money" @click="processSaleTax(scope.row, scope.$index)"></el-button>
            </el-button-group>
          </center>
        </template>
      </el-table-column>
    </el-table>
    <modal name="process-modal" height="auto" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-5 el-drawer__header">
          <span role="heading">Sales Tax Process</span>
        </div>
        <div v-if="processClient" class="col-12">
          <table class="table-sm table m-0 pt-1">
            <tbody>
              <tr>
                <td scope="row">Client Name:</td>
                <td>
                  {{ processClient.client.clientName }}
                </td>
              </tr>
              <tr>
                <td scope="row">Filing Period:</td>
                <td>
                  {{ processClient.fromDate | moment("MM-DD-YYYY") }} -
                  {{ processClient.endDate | moment("MM-DD-YYYY") }}
                </td>
              </tr>
              <tr>
                <td scope="row">Form:</td>
                <td>
                  {{ processClient.form.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="col-12" v-if="processClient">
          <div class="mt-4 mb-2">
            <span role="heading" style="color: #72767b"
              >Sales Tax Credentials:</span
            >
          </div>
          <el-table
            :data="processClient.client.corporate.credentials"
            class="w-100"
          >
            <el-table-column label="Link" prop="link"></el-table-column>
            <el-table-column label="User" prop="user"></el-table-column>
            <el-table-column label="Password" prop="password"></el-table-column>
            <el-table-column label="Pin" prop="pin"></el-table-column>
            <el-table-column label="Notes" prop="notes"></el-table-column>
          </el-table>
        </div> -->
        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="corporateProcess">Next</el-button>
        </div>
      </div>
    </modal>

    <modal name="email-modal" height="auto" width="1000px" :scrollable="true">
      <div class="row" style="padding: 15px">
        <div class="col-12 mb-2 el-drawer__header">
          <span role="heading">Email Confirmation</span>
        </div>
        <div v-if="emailClient" class="col-6">
          <el-form :model="formEmail" ref="formEmail" :hide-required-asterisk="true">
            <div class="row" style="padding: 15px">
              <div class="col-12">
                <el-form-item :label="$t('To:')" prop="to" :rules="[
                  { required: true, message: 'The field is required' },
                ]">
                  <el-input type="text" v-model="formEmail.to" placeholder="To:"></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Cc:')" prop="cc">
                  <el-input type="text" v-model="formEmail.cc" placeholder="Cc:"></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Bcc:')" prop="bcc">
                  <el-input type="text" v-model="formEmail.bcc" placeholder="Bcc:"></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('subject:')" prop="subject">
                  <el-input type="text" v-model="formEmail.subject" placeholder="Subject:"></el-input>
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Attachment:')" prop="attachment1">
                  <input type="file" class="el-input__inner" v-on:change="onFileOneChange" />
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Attachment:')" prop="attachment2">
                  <input type="file" class="el-input__inner" v-on:change="onFileTwoChange" />
                </el-form-item>
              </div>
              <div class="col-12">
                <el-form-item :label="$t('Attachment:')" prop="attachment3">
                  <input type="file" class="el-input__inner" v-on:change="onFileThreeChange" />
                </el-form-item>
              </div>
            </div>
          </el-form>
        </div>
        <div class="col-6" v-if="emailClient">
          <div class="mt-2">
            <p>Dear {{ emailClient.client.clientName }}</p>
            <p>
              Below are the details for the following Sales Tax Filing Period:
            </p>
            <table class="table-sm mb-2">
              <tbody>
                <tr>
                  <td class="pl-0" style="width: 50%">Filing Period:</td>
                  <td>
                    {{ emailClient.fromDate | moment("MM/DD/YYYY") }} -
                    {{ emailClient.endDate | moment("MM/DD/YYYY") }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Bank Name:</td>
                  <td>
                    {{
                      emailClient.client.bank
                      ? emailClient.client.bank.name
                      : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Bank Acct:</td>
                  <td>
                    {{ bankAccountX(emailClient.client.credential_banks, emailClient.client.bankId) }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Due Date:</td>
                  <td>
                    {{ emailClient.dueDate | moment("MM/DD/YYYY") }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Total Due:</td>
                  <td>${{ numberFormat(emailClient.TotalNetSales.toFixed(2)) }}</td>
                </tr>
                <tr>
                  <td class="pl-0">Payment Date:</td>
                  <td>
                    <strong>{{
                      emailClient.paymentDate | moment("MM/DD/YYYY")
                    }}</strong>
                  </td>
                </tr>
                <tr>
                  <td class="pl-0">Payment Amount:</td>
                  <td>
                    <strong>${{ numberFormat(emailClient.paymentAmount.toFixed(2)) }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>
              The payment amount will be deducted from your bank account on the
              payment date.
            </p>
            <p>Best,</p>
            <p>The Sales Tax Team</p>
          </div>
        </div>

        <div class="col-12 items-center d-flex justify-content-center">
          <el-button class="mt-4" type="primary" @click="sendEmail">Send</el-button>
        </div>
      </div>
    </modal>

    <router-link to="/admin/sale-tax/clients">
      <el-button class="fixed-bottom new-register" type="primary">Clients</el-button>
    </router-link>

    <el-button class="fixed-bottom new-register" style="margin-left: 80px" type="primary" @click="print"><i
        class="fal fa-print"></i> Print Report</el-button>
    <download-excel class="el-button fixed-bottom new-register el-button--primary fixed-bottom new-register"
      style="margin-left:440px;" :fetch="exportExcel" worksheet="Sales Tax" name="salextax.xls">
      Export Excel
    </download-excel>
    <el-button class="fixed-bottom new-register" style="margin-left: 230px" type="primary" @click="review"><i
        class="fal fa-search"></i> Review and Generate</el-button>

    <el-drawer title="Process Sales Tax" :visible.sync="componentProcess.drawer" :before-close="handleClose"
      direction="rtl" size="900px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <component-process-sale-tax v-on:refresh="load($event)" :data="componentProcess.data"
          :key="componentProcess.render" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import saleTax from "@/services/api/saleTax";
import corporate from "@/services/api/corporate";
import office from "@/services/api/office";
import officeUser from "@/services/api/officeUser";
import Multiselect from "vue-multiselect";
import ComponentProcessSaleTax from "@/components/admin/ProcessSaleTax";
import stateSalesTax from "@/services/api/stateSalesTax";
import clientSaleTax from "@/services/api/clientSaleTax";
import downloadExcel from "vue-json-excel";

export default {
  components: {
    Multiselect,
    ComponentProcessSaleTax,
    downloadExcel
  },
  data() {
    return {
      data: [],
      tableData: [],
      officeFilter: [],
      clientFilter: [],
      userFilter: [],
      statusFilter: [],
      statusClientFilter: [],
      yearFilter: [],
      quarterFilter: [],
      stateFilter: [],
      discriminator: "",
      search: "",
      offices: [],
      clients: [],
      users: [],
      status: [{ status: "PENDING" }, { status: "PROCESSED" }],
      statusClient: [{ status: "ACTIVE", value: 1 }, { status: "INACTIVE", value: 0 }],
      years: this.generateArrayOfYears(),
      quarters: [
        { quarter: "Q1" },
        { quarter: "Q2" },
        { quarter: "Q3" },
        { quarter: "Q4" },
      ],
      states: [],
      processClient: null,
      emailClient: null,
      formEmail: {
        id: null,
        to: null,
        cc: null,
        bcc: "SalesTax@CabreraBusiness.com",
        subject: null,
        atch1: null,
        atch2: null,
        atch3: null,
      },
      componentManualRecord: {
        data: null,
        drawer: false,
        render: 0,
      },
      componentProcess: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  mounted() {
    switch (this.$store.getters.discriminator) {
      case "administrator":
        this.discriminator = "administrator";
        office.get().then((response) => {
          this.offices = response;
        });
        break;
      case "office":
      case "employee": {
        this.discriminator = "office";
        let idOffice = null;
        officeUser.getOffice(this.$store.getters.id).then((response) => {
          this.offices = response.map((item) => {
            idOffice = item.office.id;
            return {
              id: item.office.id,
              office_name: item.office.office_name,
            };
          });
        });
        break;
      }
    }
    saleTax.get().then((response) => {
      this.data = Object.assign([], response);
      //this.tableData = response;
      //console.log(response);
    });
    stateSalesTax.get().then((response) => {
      this.states = response;
    });
  },
  methods: {
    exportExcel() {
      return this.tableData.map((item, i) => ({
        "Client Name": item.client.clientName,
        "Form": item.form.name,
        "State": item.form.state.name,
        "Quarter": item.year + " " + item.quarter,
        "Due Date": item.dueDate,
        "Gross Sales": new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.grossSales),
        "Non - Taxable": new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.nonTaxable),
        "Net Tax Due": new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.NetTaxableSales),
        "Tax Payment": new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.paymentAmount),
        "Processed": item.processed,
        "Processed By": item.responsible.name
      }))
    },
    numberFormat(number) {
      const exp = /(\d)(?=(\d{3})+(?!\d))/g;
      const rep = '$1,';
      let arr = number.toString().split('.');
      arr[0] = arr[0].replace(exp, rep);
      return arr[1] ? arr.join('.') : arr[0];
    },
    bankAccountX(bankAccount, bankId) {
      let bank = bankAccount.find(elm => elm.bankId == bankId).account
      let bankAccountX =
        bank.length > 4
          ? "x".repeat(bank.length - 4) +
          bank.substr(bank.length - 4)
          : bank;
      return bankAccountX
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear();
      var min = max - 9;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push({ year: i });
      }
      return years;
    },
    load(event) {
      this.componentProcess.drawer = false;
      this.email(event.data);
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    getClients(idOffices) {
      this.clients = [];
      idOffices.forEach((id) => {
        corporate.getClientsByOffice(id).then((response) => {
          this.clients = this.clients.concat(response);
        });
      });
    },
    getUsers(idOffices) {
      this.users = [];
      idOffices.forEach((id) => {
        officeUser.getUsersOffice(id).then((response) => {
          this.users = this.users.concat(
            response.map((userOffice) => userOffice.user)
          );
        });
      });
    },
    filterData() {
      let idOffices = this.officeFilter.map((office) => {
        return office.id;
      });

      let clients = this.clientFilter.map((client) => {
        return client.uuid;
      });

      let users = this.userFilter.map((user) => {
        return user.id;
      });
      let statusClient = this.statusClientFilter.map((status) => {
        return status.value;
      });
      let status = this.statusFilter.map((status) => {
        return status.status;
      });

      let years = this.yearFilter.map((year) => {
        return year.year.toString();
      });

      let quarters = this.quarterFilter.map((quarter) => {
        return quarter.quarter;
      });

      let states = this.stateFilter.map((state) => {
        return state.id;
      });

      this.tableData = Object.assign([], this.data);

      if (idOffices.length > 0) {
        this.tableData = this.tableData.filter(
          (item) =>
            idOffices.indexOf(item.client.responsible.office_user.office) != -1
        );
      }

      if (idOffices.length !== this.lengthOfficeFilter) {
        this.lengthOfficeFilter = idOffices.length;
        this.getClients(idOffices);
        this.getUsers(idOffices);
      }
      if (idOffices.length == 0) {
        this.lengthOfficeFilter = 0;
        this.clientFilter = [];
        this.clients = [];
        this.userFilter = [];
        this.users = [];
      }

      if (clients.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => clients.indexOf(item.client.uuid) != -1
        );
      }

      if (users.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => users.indexOf(item.client.responsible.id) != -1
        );
      }
      if (statusClient.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => statusClient.indexOf(item.client.active) != -1
        );
      }
      console.log(this.tableData)
      if (status.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => status.indexOf(item.processed) != -1
        );
      }

      if (years.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => years.indexOf(item.year) != -1
        );
      }

      if (quarters.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => quarters.indexOf(item.quarter) != -1
        );
      }

      if (states.length > 0) {
        this.tableData = this.tableData.filter(
          (item) => states.indexOf(item.form.stateId) != -1
        );
      }
    },
    print() {
      let offices = JSON.stringify(
        this.officeFilter.map((office) => {
          return { id: office.id, name: office.office_name };
        })
      );
      let clients = JSON.stringify(
        this.clientFilter.map((client) => {
          return { uuid: client.uuid, name: client.name };
        })
      );
      let statusClients = JSON.stringify(
        this.statusClientFilter.map((status) => {
          return { status: status.status, value: status.value };
        })
      );
      let status = JSON.stringify(
        this.statusFilter.map((status) => {
          return status.status;
        })
      );

      let years = JSON.stringify(
        this.yearFilter.map((year) => {
          return year.year;
        })
      );

      let quarters = JSON.stringify(
        this.quarterFilter.map((quarter) => {
          return quarter.quarter;
        })
      );
      let states = JSON.stringify(
        this.stateFilter.map((state) => {
          return { state: state.id, name: state.name };
        })
      );
      let routeData = this.$router.resolve({
        name: "SaleTaxPrint",
        query: {
          offices: offices,
          clients: clients,
          statusClients: statusClients,
          status: status,
          years: years,
          quarters: quarters,
          states: states
        },
      });
      window.open(routeData.href, "_blank");
    },
    review() {
      clientSaleTax.reviewAndGenerate().then((response) => {
        saleTax.get().then((response) => {
          this.data = Object.assign([], response);
          this.tableData = response;
          console.log(response);
        });
      })
    },
    addProcess(data) {
      this.componentProcess.drawer = true;
      this.componentProcess.render += 1;
      this.componentProcess.data = { new: true, client: data };
    },
    show_modal_process() {
      this.$modal.show("process-modal");
    },
    hide_modal_process() {
      this.$modal.hide("process-modal");
    },
    corporateProcess() {
      this.hide_modal_process();
      this.declaration(this.processClient);
    },
    processSaleTax(row, index) {
      this.index = index;
      if (row.client.corporate != null) {
        this.processClient = Object.assign({}, row);
        this.show_modal_process();
      } else {
        this.declaration(row);
      }
      //
    },
    onFileOneChange(e) {
      console.log(e.target.files[0]);
      this.formEmail.atch1 = e.target.files[0];
    },
    onFileTwoChange(e) {
      console.log(e.target.files[0]);
      this.formEmail.atch2 = e.target.files[0];
    },
    onFileThreeChange(e) {
      console.log(e.target.files[0]);
      this.formEmail.atch3 = e.target.files[0];
    },
    declaration(data) {
      this.componentProcess.drawer = true;
      this.componentProcess.render += 1;
      this.componentProcess.data = { new: false, tax: data };
    },
    show_modal_email() {
      this.$modal.show("email-modal");
    },
    hide_modal_email() {
      this.$modal.hide("email-modal");
    },
    handleClose(done) {
      this.$confirm("Are you sure you want to close this?")
        .then((_) => {
          done();
        })
        .catch((_) => { });
    },
    email(data) {
      console.log(data);
      this.emailClient = data;
      this.formEmail.id = this.emailClient.id;
      this.formEmail.subject =
        data.client.clientName +
        " - " +
        data.year +
        " " +
        data.quarter +
        " Sales Tax - Confirmation";
      if (data.client.corporate !== null) {
        if (data.client.corporate.shareholders.length > 0) {
          const shareholder = data.client.corporate.shareholders[0];
          this.formEmail.to = shareholder.email;
        }
      }
      this.show_modal_email();
    },
    sendEmail() {
      const config = {
        headers: { "content-type": "multipart/form-data" },
      };

      let formData = new FormData();

      formData.append("id", this.formEmail.id);
      formData.append("to", this.formEmail.to);
      formData.append("cc", this.formEmail.cc);
      formData.append("bcc", this.formEmail.bcc);
      formData.append("subject", this.formEmail.subject);
      formData.append("atch1", this.formEmail.atch1);
      formData.append("atch2", this.formEmail.atch2);
      formData.append("atch3", this.formEmail.atch3);

      saleTax
        .email(formData, config)
        .then((response) => {
          this.formEmail = {
            id: null,
            to: null,
            cc: null,
            bcc: "SalesTax@CabreraBusiness.com",
            subject: null,
            atch1: null,
            atch2: null,
            atch3: null,
          };
          this.hide_modal_email();
        })
        .catch(() => {
          this.$message({
            message: "Opps... Something wrong",
            type: "error",
            customClass: "message-error",
          });
        });
    },
    remove(row, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#67C23A",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          saleTax
            .delete(row)
            .then((response) => {
              this.tableData.splice(index, 1);
              this.data.splice(index, 1);
            })
            .catch(() => {
              this.$message({
                message: "Opps... Something wrong",
                type: "error",
                customClass: "message-error",
              });
            });
        }
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
