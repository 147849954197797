import axios from 'axios'

export default {
    async get() {
        try {
            const response = await axios.get('/salestaxes');
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/saletax', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async email(data,config) {
        try {
            const response = await axios.post('/saletax/email', data, config);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/saletax/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async delete(data) {
        try {
            const response = await axios.put(`/saletax/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
