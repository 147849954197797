<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <h5 class="mb-3">Filing Period To:</h5>
      <div class="row">
        <div class="col-4">
          <el-form-item :label="'From'" prop="fromDate">
            <el-date-picker
              type="date"
              v-model="form.fromDate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'To'" prop="endDate">
            <el-date-picker
              type="date"
              v-model="form.endDate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
        <div class="col-4">
          <el-form-item :label="'Due Date'" prop="dueDate">
            <el-date-picker
              type="date"
              v-model="form.dueDate"
              value-format="yyyy-MM-dd"
              format="MM/dd/yyyy"
              placeholder="Pick a day"
              class="w-100"
            ></el-date-picker>
          </el-form-item>
        </div>
      </div>
      <hr class="mb-5" />
      <div class="row">
        <div class="col-6">
          <el-form-item :label="'Quarter'" prop="quarter">
            <el-select
              v-model="form.quarter"
              placeholder="Select Quarter"
              class="w-100"
            >
              <el-option value="1Q" label="1Q">1Q</el-option>
              <el-option value="2Q" label="2Q">2Q</el-option>
              <el-option value="3Q" label="3Q">3Q</el-option>
              <el-option value="4Q" label="4Q">4Q</el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item :label="'Year'" prop="year">
            <el-select
              v-model="form.year"
              placeholder="Select Year"
              class="w-100"
            >
              <el-option
                v-for="year in arrayOfYears"
                :key="year.year"
                :value="year.year"
                :label="year.year"
                >{{ year.year }}</el-option
              >
            </el-select>
          </el-form-item>
        </div>
      </div>

      <el-form-item :label="'Gross Sales:'" prop="grossSales">
        <el-input type="number" v-model="form.grossSales"></el-input>
      </el-form-item>

      <el-form-item :label="'Total Non - Taxable Sales:'" prop="nonTaxable">
        <el-input type="number" v-model="form.nonTaxable"></el-input>
      </el-form-item>

      <el-form-item
        :label="'Gross Credit and Debit Card Deposits:'"
        prop="grossCreditAndDebitCard"
      >
        <el-input
          type="number"
          v-model="form.grossCreditAndDebitCard"
        ></el-input>
      </el-form-item>

      <el-form-item
        :label="'Net Taxable Sales & Services:'"
        prop="NetTaxableSales"
      >
        <el-input type="number" v-model="form.NetTaxableSales"></el-input>
      </el-form-item>

      <el-form-item :label="'Total Net Sales & Use Tax:'" prop="TotalNetSales">
        <el-input type="number" v-model="form.TotalNetSales"></el-input>
      </el-form-item>

      <el-form-item :label="'Payment Amount:'" prop="paymentAmount">
        <el-input type="number" v-model="form.paymentAmount"></el-input>
      </el-form-item>

      <el-form-item :label="'Payment Date:'" prop="paymentDate">
        <el-date-picker
          type="date"
          v-model="form.paymentDate"
          value-format="yyyy-MM-dd"
          format="MM/dd/yyyy"
          placeholder="Pick a day"
          class="w-100"
        ></el-date-picker>
      </el-form-item>

      <el-form-item :label="'Confirmation Number:'" prop="confirmationNumber">
        <el-input type="text" v-model="form.confirmationNumber"></el-input>
      </el-form-item>

      <el-button
        type="primary"
        @click="data.new ? submitForm('form') : updateForm('form')"
        >Finish Tax</el-button
      >
    </el-form>
  </div>
</template>

<script>
import saleTax from "@/services/api/saleTax";

export default {
  props: ["data"],
  data() {
    return {
      form: {
        id: null,
        client: null,
        formId: null,
        fromDate: null,
        endDate: null,
        dueDate: null,
        quarter: null,
        year: null,
        grossSales: null,
        nonTaxable: null,
        grossCreditAndDebitCard: null,
        NetTaxableSales: null,
        TotalNetSales: null,
        paymentAmount: null,
        paymentDate: null,
        confirmationNumber: null,
        processed: "PROCESSED",
        responsible: null,
      },
    };
  },
  mounted() {
    //
    this.form.responsible = this.$store.getters.id;

    if (this.data.new === true) {
      this.form.client = this.data.client.id;
      this.form.formId = this.data.client.formId;
    } else {
      this.form.id = this.data.tax.id;
      this.form.client = this.data.tax.client.id;
      this.form.formId = this.data.tax.formId;
      this.form.fromDate = this.data.tax.fromDate;
      this.form.endDate = this.data.tax.endDate;
      this.form.dueDate = this.data.tax.dueDate;
      this.form.quarter = this.data.tax.quarter;
      this.form.year = this.data.tax.year;
      this.form.grossSales = this.data.tax.grossSales;
      this.form.nonTaxable = this.data.tax.nonTaxable;
      this.form.grossCreditAndDebitCard = this.data.tax.grossCreditAndDebitCard;
      this.form.NetTaxableSales = this.data.tax.NetTaxableSales;
      this.form.TotalNetSales = this.data.tax.TotalNetSales;
      this.form.paymentAmount = this.data.tax.paymentAmount;
      this.form.paymentDate = this.data.tax.paymentDate;
      this.form.confirmationNumber = this.data.tax.confirmationNumber;
      this.form.processed = "PROCESSED";
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saleTax
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saleTax
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    arrayOfYears() {
      var max = new Date().getFullYear();
      var min = max - 9;
      var years = [];

      for (var i = max; i >= min; i--) {
        years.push({ year: i });
      }
      return years;
    },
  },
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
